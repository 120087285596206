import { toast } from "vue3-toastify";

interface NotifyOptions {
  type?: "info" | "success" | "error" | "default";
  duration?: number;
}

export const useNotify = () => {
  return (content: string, options: NotifyOptions = {}) => {
    const type = options.type ?? "default";

    toast(content, {
      type,
      hideProgressBar: true,
      theme: "colored",
      icon: false,
      closeButton: false,
      autoClose: options.duration ?? 5000,
    });
  };
};
